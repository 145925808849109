<template>
    <div>
        <div style="width: 100%; height: 50px; background: #ffffff">
            <span style="margin-left: 10px; line-height: 50px">
                {{
        userForm.UserName
                }}
            </span>
        </div>
        <!-- <div class="new_content"
             style="width: 100%; height: 80%; margin-top: 20px; background: #ffffff">
            <el-tabs v-model="activeName"
                     style="margin-left: 10px"
                     @tab-click="handleClick">
                <el-tab-pane label="基本信息" name="基本信息">
                    <el-row :gutter="20" style="margin-top: 10px">
                        <el-col :span="5">
                            <div class="grid-content bg-purple">
                                <el-card class="box-card">
                                    <div class="name-role">
                                        <img v-lazy="userForm.ImageURL" height="150" width="150" />
                                    </div>
                                    <el-divider></el-divider>
                                    <div class="personal-relation">
                                        <div class="relation-item">
                                            姓名:
                                            <div style="float: right; padding-right: 20px">
                                                {{ userForm.UserName }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="personal-relation">
                                        <div class="relation-item">
                                            部门:
                                            <div style="float: right; padding-right: 20px">
                                                {{ userForm.Dpt }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="personal-relation">
                                        <div class="relation-item">
                                            岗位:
                                            <div style="float: right; padding-right: 20px">
                                                {{ userForm.Post }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="personal-relation">
                                        <div class="relation-item">
                                            入职日期:
                                            <div style="float: right; padding-right: 20px">
                                                {{ userForm.Hiredate }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="personal-relation">
                                        <div class="relation-item">
                                            手机号:
                                            <div style="float: right; padding-right: 20px">
                                                {{ userForm.MobilePhone }}
                                            </div>
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="19">
                            <div class="grid-content bg-purple">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>基本信息</span>
                                    </div>
                                    <el-row :gutter="20">
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    填单日期: {{ dataForm.ApplyDate }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    姓名: {{ dataForm.Name }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    出生年月: {{ dataForm.Birthday }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    住址电话: {{ dataForm.MobilePhone }}
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-divider></el-divider>
                                    <el-row :gutter="20">
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    性别: {{ dataForm.Sex }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    民族: {{ dataForm.Nationality }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    第一学历: {{ dataForm.FirstDiploma }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    职称: {{ dataForm.Position }}
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-divider></el-divider>
                                    <el-row :gutter="20">
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    外语级别: {{ dataForm.LanguageLevel }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    健康状况: {{ dataForm.Health }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    非全日制学历: {{ dataForm.TopDiploma }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    手机号码: {{ dataForm.MobilePhone }}
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-divider></el-divider>
                                    <el-row :gutter="20">
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    政治面貌: {{ dataForm.PoliticsStatus }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    户口性质: {{ dataForm.NativeStatus }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    身份证号: {{ dataForm.IDCard }}
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-divider></el-divider>
                                    <el-row :gutter="20">
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    电子邮箱: {{ dataForm.Email }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    通讯地址: {{ dataForm.Address }}
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-divider></el-divider>
                                    <el-row :gutter="20">
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    招聘来源: {{ dataForm.ApplySource }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="personal-relation">
                                                <div class="relation-item">
                                                    紧急联系人: {{ dataForm.Connect_UserName }}
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </el-card>
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>学习经历(高中,大学，在职进修)</span>
                                    </div>
                                    <el-row>
                                        <el-table :data="dataForm.Educateds" style="width: 100%">
                                            <el-table-column prop="EnterTime"
                                                             label="开始时间"
                                                             :formatter="formatStartTime"></el-table-column>
                                            <el-table-column prop="GraduateTime"
                                                             label="结束时间"
                                                             :formatter="formatStartTime"></el-table-column>
                                            <el-table-column prop="GradauteSchool"
                                                             label="学校名称"></el-table-column>
                                            <el-table-column prop="Speciality"
                                                             label="专业"></el-table-column>
                                            <el-table-column prop="Certificate"
                                                             label="学历"></el-table-column>
                                            <el-table-column prop="Voucher"
                                                             label="证明人及电话"></el-table-column>
                                        </el-table>
                                    </el-row>
                                </el-card>
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>培训经历</span>
                                    </div>
                                    <el-row>
                                        <el-table :data="dataForm.Traineds" style="width: 100%">
                                            <el-table-column prop="StartTime"
                                                             label="开始时间"
                                                             :formatter="formatStartTime"></el-table-column>
                                            <el-table-column prop="EndTime"
                                                             label="结束时间"
                                                             :formatter="formatStartTime"></el-table-column>
                                            <el-table-column prop="Institutions"
                                                             label="培训机构"></el-table-column>
                                            <el-table-column prop="Details"
                                                             label="培训内容"></el-table-column>
                                            <el-table-column prop="InCertificate"
                                                             label="证书名称"></el-table-column>
                                        </el-table>
                                    </el-row>
                                </el-card>
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>工作经历</span>
                                    </div>
                                    <el-row>
                                        <el-table :data="dataForm.Workeds" style="width: 100%">
                                            <el-table-column prop="StartTime"
                                                             label="开始时间"
                                                             :formatter="formatStartTime"></el-table-column>
                                            <el-table-column prop="EndTime"
                                                             label="结束时间"
                                                             :formatter="formatStartTime"></el-table-column>
                                            <el-table-column prop="WorkAddress"
                                                             label="工作单位"></el-table-column>
                                            <el-table-column prop="LeavingReason"
                                                             label="职位/岗位"></el-table-column>
                                            <el-table-column prop="BecaseWork"
                                                             label="离职原因"></el-table-column>
                                            <el-table-column prop="WorkVoucher"
                                                             label="证明人及电话"></el-table-column>
                                        </el-table>
                                    </el-row>
                                </el-card>
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>家庭成员</span>
                                    </div>
                                    <el-row>
                                        <el-table :data="dataForm.Familys" style="width: 100%">
                                            <el-table-column prop="FaName"
                                                             label="姓名"></el-table-column>
                                            <el-table-column prop="Relationship"
                                                             label="与本人关系"></el-table-column>
                                            <el-table-column prop="WorkDpt"
                                                             label="职位/岗位"></el-table-column>
                                            <el-table-column prop="WorkUnits"
                                                             label="工作单位"></el-table-column>
                                            <el-table-column prop="PhoneNo"
                                                             label="联系电话"></el-table-column>=
                                        </el-table>
                                    </el-row>
                                </el-card>
                            </div>
                        </el-col>
                    </el-row>
                </el-tab-pane>

                <el-tab-pane label="合同信息" name="合同信息">
                    <el-table :data="Contract" highlight-current-row>
                        <el-table-column prop="UserName" label="姓名"> </el-table-column>
                        <el-table-column label="合同类型"
                                         prop="ConTypeName"></el-table-column>
                        <el-table-column label="合同开始时间"
                                         prop="StartDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="合同结束时间"
                                         prop="EndDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="合同期限" prop="ConDate"></el-table-column>
                        <el-table-column label="类型" prop="Type"></el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="证书信息" name="证书信息">
                    <el-table :data="Certificate" highlight-current-row>
                        <el-table-column prop="UserName" label="姓名"> </el-table-column>
                        <el-table-column prop="CertType" label="证书系列">
                        </el-table-column>
                        <el-table-column label="证书名称" prop="CertName"></el-table-column>
                        <el-table-column label="证书类别"
                                         prop="CertCategory"></el-table-column>
                        <el-table-column label="专业"
                                         prop="CertProfession"></el-table-column>
                        <el-table-column label="证书状态"
                                         prop="CertState"></el-table-column>
                        <el-table-column label="补贴金"
                                         prop="SubsidyMoney"></el-table-column>
                        <el-table-column fixed="right" label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="CertificateDetail(scope.row)"
                                           type="text"
                                           size="small">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-dialog title="证书详情"
                               :visible.sync="SakaryVisible"
                               v-model="SakaryVisible"
                               :close-on-click-modal="false">
                        <el-form ref="SakaryForm" :model="SakaryForm" label-width="100px">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="姓名:" prop="UserName">
                                        <el-input type="text"
                                                  :disabled="true"
                                                  v-model="SakaryForm.UserName">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="证书名称:" prop="CertName">
                                        <el-input type="text" v-model="SakaryForm.CertName">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="证书系列:" prop="CertType">
                                        <el-select v-model="SakaryForm.CertType"
                                                   placeholder="证书系列"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="类别:" prop="CertCategory">
                                        <el-select v-model="SakaryForm.CertCategory"
                                                   placeholder="类别"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="专业:" prop="CertProfession">
                                        <el-select v-model="SakaryForm.CertProfession"
                                                   placeholder="证书系列"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="证书编号:" prop="CertCode">
                                        <el-input type="text" v-model="SakaryForm.CertCode">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="注册编号:" prop="RegisterCode">
                                        <el-input type="text" v-model="SakaryForm.RegisterCode">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="管理号:" prop="ManageCode">
                                        <el-input type="text" v-model="SakaryForm.ManageCode">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="发证单位:" prop="CertAssUnit">
                                        <el-input type="text" v-model="SakaryForm.CertAssUnit">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="评定单位:" prop="CertFairUnit">
                                        <el-input type="text" v-model="SakaryForm.CertFairUnit">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="签发时间:" prop="CertFairDate">
                                        <el-date-picker type="date"
                                                        v-model="SakaryForm.CertFairDate"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="变更日期:" prop="ChangeDate">
                                        <el-date-picker type="date"
                                                        v-model="SakaryForm.ChangeDate"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="有效期限:" prop="CertEndDate">
                                        <el-date-picker type="date"
                                                        v-model="SakaryForm.CertEndDate"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="存档日期:" prop="CertSaveDate">
                                        <el-date-picker type="date"
                                                        v-model="SakaryForm.CertSaveDate"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="补贴日期:" prop="SubsidyDate">
                                        <el-date-picker type="date"
                                                        v-model="SakaryForm.SubsidyDate"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="补贴类型:" prop="SubsidiesType">
                                        <el-select v-model="SakaryForm.SubsidiesType" placeholder="选择" style="width: 100%">
                                            <el-option label="证书补贴" value="证书补贴"></el-option>
                                            <el-option label="技能津贴" value="技能津贴"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="补贴金额:" prop="SubsidyMoney">
                                        <el-input type="money" v-model="SakaryForm.SubsidyMoney">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="复审周期:" prop="CertCycle">
                                        <el-select v-model="SakaryForm.CertCycle"
                                                   placeholder="复审周期"
                                                   style="width: 100%">
                                            <el-option label="0" value="0"></el-option>
                                            <el-option label="1" value="1"></el-option>
                                            <el-option label="2" value="2"></el-option>
                                            <el-option label="3" value="3"></el-option>
                                            <el-option label="4" value="4"></el-option>
                                            <el-option label="5" value="5"></el-option>
                                            <el-option label="6" value="6"></el-option>
                                            <el-option label="7" value="7"></el-option>
                                            <el-option label="8" value="8"></el-option>
                                            <el-option label="9" value="9"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="发放周期:" prop="CerFFTime">
                                        <el-select v-model="SakaryForm.CerFFTime"
                                                   placeholder="发放周期"
                                                   style="width: 100%">
                                            <el-option label="无" value="无"></el-option>
                                            <el-option label="半年度" value="半年度"></el-option>
                                            <el-option label="季度" value="季度"></el-option>
                                            <el-option label="月度" value="月度"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="发放方式:" prop="CerFFType">
                                        <el-select v-model="SakaryForm.CerFFType"
                                                   placeholder="发放方式"
                                                   style="width: 100%">
                                            <el-option label="无" value="无"></el-option>
                                            <el-option label="集团发放" value="集团发放"></el-option>
                                            <el-option label="工资发放" value="工资发放"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="发放公司:" prop="PaymentAccount">
                                        <el-select v-model="SakaryForm.PaymentAccount"
                                                   placeholder="发放公司"
                                                   style="width: 100%">
                                            <el-option label="无" value="无"></el-option>
                                            <el-option label="格瑞德集团" value="格瑞德集团"></el-option>
                                            <el-option label="人工环境设计研究院" value="人工环境设计研究院"></el-option>
                                            <el-option label="冷却设备有限公司" value="冷却设备有限公司"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="获取方式:" prop="CertGetType">
                                        <el-select v-model="SakaryForm.CertGetType"
                                                   placeholder="获取方式"
                                                   style="width: 100%">
                                            <el-option label="个人" value="个人"></el-option>
                                            <el-option label="公司" value="公司"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="证书状态:" prop="CertState">
                                        <el-select v-model="SakaryForm.CertState"
                                                   placeholder="证书状态"
                                                   style="width: 100%">
                                            <el-option label="在职未过期"
                                                       value="在职未过期"></el-option>
                                            <el-option label="在职已过期"
                                                       value="在职已过期"></el-option>
                                            <el-option label="离职未过期"
                                                       value="离职未过期"></el-option>
                                            <el-option label="离职已过期"
                                                       value="离职已过期"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="存放地点:" prop="SavePlace">
                                        <el-select v-model="SakaryForm.SavePlace"
                                                   placeholder="存放地点">
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="使用有效期:" prop="ValidityDate">
                                        <el-date-picker type="date"
                                                        v-model="SakaryForm.ValidityDate"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="存放状态变更说明(借阅记录):"
                                                  prop="CertSaveState"
                                                  label-width="200px">
                                        <el-select v-model="SakaryForm.CertSaveState"
                                                   placeholder="选择">
                                            <el-option label="已借用" value="已借用"></el-option>
                                            <el-option label="未借用" value="未借用"></el-option>
                                            <el-option label="综合办公室借用"
                                                       value="综合办公室借用"></el-option>
                                            <el-option label="离职带走" value="离职带走"></el-option>
                                            <el-option label="离职未带走"
                                                       value="离职未带走"></el-option>
                                            <el-option label="换证复审" value="换证复审"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-dialog>
                </el-tab-pane>
                <el-tab-pane label="调动信息" name="调动信息">
                    <el-table :data="Transfer" highlight-current-row>
                        <el-table-column prop="UserName" label="名称"> </el-table-column>
                        <el-table-column label="调入公司"
                                         prop="IntoFactory"></el-table-column>
                        <el-table-column label="调入部门" prop="IntoDpt"></el-table-column>
                        <el-table-column label="调入岗位" prop="IntoPost"></el-table-column>
                        <el-table-column label="调出公司"
                                         prop="OutFactory"></el-table-column>
                        <el-table-column label="调出部门" prop="OutDpt"></el-table-column>
                        <el-table-column label="调出岗位" prop="OutPost"></el-table-column>
                        <el-table-column label="用工性质"
                                         prop="ContractNature"></el-table-column>
                        <el-table-column label="调动时间"
                                         prop="TransferDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column fixed="right" label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="TransferDetail(scope.row)"
                                           type="text"
                                           size="small">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-dialog title="调动详情"
                               :visible.sync="TransferFormVisible"
                               v-model="TransferFormVisible"
                               :close-on-click-modal="false">
                        <el-form ref="TransferForm"
                                 :model="TransferForm"
                                 class="demo-form-inline"
                                 label-width="100px">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="姓名:" prop="UserName">
                                        <el-input type="text" v-model="TransferForm.UserName">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="调入公司:" prop="IntoFactory">
                                        <el-input type="text" v-model="TransferForm.IntoFactory">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="调入部门:" prop="IntoDpt">
                                        <el-input type="text" v-model="TransferForm.IntoDpt">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="调入岗位:" prop="IntoPost">
                                        <el-input type="text" v-model="TransferForm.IntoPost">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="调出公司:" prop="OutFactory">
                                        <el-input type="text" v-model="TransferForm.OutFactory">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="调出部门:" prop="OutDpt">
                                        <el-input type="text"
                                                  :disabled="true"
                                                  v-model="TransferForm.OutDpt">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="调出岗位:" prop="OutPost">
                                        <el-input type="text" v-model="TransferForm.OutPost">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="调动原因:" prop="Reason">
                                        <el-input type="text" v-model="TransferForm.Reason">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="调动日期:" prop="TransferDate">
                                        <el-date-picker type="date"
                                                        v-model="TransferForm.TransferDate"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="用工性质:">
                                        <el-select v-model="TransferForm.ContractNature"
                                                   placeholder="请选择"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <upload-files :files="TransferForm.FileArry"
                                          :key="key"
                                          action="/cyl/ftp/ftp/upload"
                                          :limit="20"
                                          :IsDisabled="false"
                                          :IsDel="false"></upload-files>
                        </el-form>
                    </el-dialog>
                </el-tab-pane>
                <el-tab-pane label="薪资信息" name="薪资信息">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    姓名: {{ PresentSalary.UserName }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    银行名称: {{ PresentSalary.BankCardName }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    银行卡号: {{ PresentSalary.BankCardCD }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    是否有年功: {{ PresentSalary.IsYear }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    超龄年功工资: {{ PresentSalary.OverAgeUserAvailable }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    岗位工资: {{ PresentSalary.WorkSalary }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    职务工资: {{ PresentSalary.PostSalaryName }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    工资类型: {{ PresentSalary.SalaryType }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    日保健费: {{ PresentSalary.CareDaySalary }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    日工资单价: {{ PresentSalary.DaySalary }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    特岗补贴: {{ PresentSalary.SpecialSalary }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    伙食单价: {{ PresentSalary.EateDaySalary }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    驻外补贴: {{ PresentSalary.OutsideSalary }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    车辆补贴: {{ PresentSalary.BusMoney }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    值班日保健费: {{ PresentSalary.OndutyCareSalary }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    爱心基金: {{ PresentSalary.LoveMoney }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    工人系数: {{ PresentSalary.WorkerMod }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    租房补贴: {{ PresentSalary.HouseSalary }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    绩效工资: {{ PresentSalary.PerfSalary }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    备注: {{ PresentSalary.Remark }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                </el-tab-pane>
                <el-tab-pane label="荣誉信息" name="荣誉信息">
                    <el-table :data="Honor" highlight-current-row>
                        <el-table-column prop="UserName" label="姓名"> </el-table-column>
                        <el-table-column label="公司" prop="Factory"></el-table-column>
                        <el-table-column label="部门" prop="Dpt"></el-table-column>
                        <el-table-column label="岗位" prop="Post"></el-table-column>
                        <el-table-column label="分类" prop="Type"></el-table-column>
                        <el-table-column label="是否在公司" prop="IsFactory">
                            <template slot-scope="scope">
                                <el-tag :type="scope.row.IsFactory ? 'success' : 'danger'"
                                        disable-transitions>
                                    {{ scope.row.IsFactory ? "是" : "否" }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="荣誉级别" prop="Level"></el-table-column>

                        <el-table-column label="获取时间"
                                         prop="GetDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="审核状态" prop="State">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.State == '已确认'"
                                        type="success"
                                        disable-transitions>
                                    {{ scope.row.State }}
                                </el-tag>
                                <el-tag v-else-if="scope.row.State == '已退回'"
                                        type="danger"
                                        disable-transitions>
                                    {{ scope.row.State }}
                                </el-tag>
                                <el-tag v-else-if="scope.row.State == '未确认'"
                                        type="primary"
                                        disable-transitions>
                                    {{ scope.row.State }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="HonorDetail(scope.row)"
                                           type="text"
                                           size="small">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-pagination align="center"
                                   @current-change="handleCurrentChange"
                                   :current-page="currentPage"
                                   :page-size="pageSize"
                                   layout="prev, pager, next, jumper"
                                   :page-count="total"
                                   :total="totaldata">
                    </el-pagination>

                    <el-dialog title="荣誉详情"
                               :visible.sync="HonorVisible"
                               v-model="HonorVisible"
                               :close-on-click-modal="false">
                        <el-form ref="HonorForm"
                                 :model="HonorForm"
                                 class="demo-form-inline"
                                 label-width="100px">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="姓名:" prop="UserName">
                                        <el-input type="text" v-model="HonorForm.UserName">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="公司:" prop="Factory">
                                        <el-input type="text" v-model="HonorForm.Factory">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="部门:" prop="Dpt">
                                        <el-input type="text" v-model="HonorForm.Dpt"> </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="岗位:" prop="Post">
                                        <el-input type="text" v-model="HonorForm.Post"> </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="分类:" prop="Type">
                                        <el-select v-model="HonorForm.Type"
                                                   placeholder="请选择"
                                                   style="width: 100%">
                                            <el-option label="荣誉称号" value="荣誉称号"></el-option>
                                            <el-option label="荣誉奖励" value="荣誉奖励"></el-option>
                                            <el-option label="专利" value="专利"></el-option>
                                            <el-option label="论文" value="论文"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="荣誉级别:" prop="Level">
                                        <el-select v-model="HonorForm.Level"
                                                   placeholder="请选择"
                                                   style="width: 100%">
                                            <el-option label="国家级" value="国家级"></el-option>
                                            <el-option label="省级" value="省级"></el-option>
                                            <el-option label="市级" value="市级"></el-option>
                                            <el-option label="区级" value="区级"></el-option>
                                            <el-option label="公司级" value="公司级"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="获取时间:" prop="GetDate">
                                        <el-date-picker type="date"
                                                        v-model="HonorForm.GetDate"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="颁发单位:" prop="IssuedUnits">
                                        <el-input type="text" v-model="HonorForm.IssuedUnits">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="是否在公司:" prop="IsFactory">
                                        <el-switch v-model="HonorForm.IsFactory"> </el-switch>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="具体描述">
                                        <el-input type="textarea"
                                                  v-model="HonorForm.Explain"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <upload-files :files="HonorForm.FileArry"
                                          :key="key"
                                          action="/cyl/ftp/ftp/upload"
                                          :limit="20"
                                          :IsDisabled="false"
                                          :IsDel="false"></upload-files>
                        </el-form>
                    </el-dialog>
                </el-tab-pane>
                <el-tab-pane label="离职记录" name="离职记录">
                    <el-table :data="Dimission" highlight-current-row>
                        <el-table-column prop="UserName" label="姓名"> </el-table-column>
                        <el-table-column label="公司" prop="Factory"></el-table-column>
                        <el-table-column label="部门" prop="Dpt"></el-table-column>
                        <el-table-column label="岗位" prop="Post"></el-table-column>
                        <el-table-column label="离职时间"
                                         prop="DimissionDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="聘用时间"
                                         prop="Hiredate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="离职类型" prop="Type"></el-table-column>
                        <el-table-column label="员工性质"
                                         prop="ContractNature"></el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="薪资调整记录" name="薪资调整记录">
                    <el-table :data="SalaryBanks" highlight-current-row>
                        <el-table-column label="确认时间"
                                         prop="VerifyDate" :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="岗位工资"
                                         prop="WorkSalary"></el-table-column>
                        <el-table-column label="职务工资"
                                         prop="PostSalary"></el-table-column>
                        <el-table-column label="绩效工资"
                                         prop="PerfSalary"></el-table-column>
                        <el-table-column label="工资类型"
                                         prop="SalaryType"></el-table-column>
                        <el-table-column label="是否有年功" prop="IsYear"></el-table-column>
                        <el-table-column label="备注"
                                         prop="Remark"></el-table-column>
                        <el-table-column fixed="right" label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="SalaryBanksDetail(scope.row)"
                                           type="text"
                                           size="small">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-dialog title="薪资调整记录详情"
                               :visible.sync="SalaryBanksVisible"
                               v-model="SalaryBanksVisible"
                               :close-on-click-modal="false">
                        <el-form ref="SalaryBanksForm"
                                 :model="SalaryBanksForm"
                                 class="demo-form-inline"
                                 label-width="120px">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="姓名:" prop="UserName">
                                        <el-input type="text" v-model="SalaryBanksForm.UserName">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="确认时间:" prop="VerifyDate">
                                        <el-input type="text" v-model="SalaryBanksForm.VerifyDate" :formatter="formatStartTime" :disabled="true">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="银行名称:" prop="BankCardName">
                                        <el-input type="text"
                                                  v-model="SalaryBanksForm.BankCardName">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="银行卡号:" prop="BankCardCD">
                                        <el-input type="text" v-model="SalaryBanksForm.BankCardCD">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="是否有年功:" prop="IsYear">
                                        <el-select v-model="SalaryBanksForm.IsYear"
                                                   placeholder="是否有年功"
                                                   style="width: 100%">
                                            <el-option label="是" value="是"></el-option>
                                            <el-option label="否" value="否"></el-option>
                                            <el-option label="超龄年功" value="超龄年功"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="超龄年功工资:"
                                                  prop="OverAgeUserAvailable">
                                        <el-input type="text"
                                                  v-model="SalaryBanksForm.OverAgeUserAvailable">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="岗位工资:" prop="WorkSalary">
                                        <el-input type="text" v-model="SalaryBanksForm.WorkSalary">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="职务工资:" prop="PostSalary">
                                        <el-select v-model="SalaryBanksForm.PostSalary"
                                                   placeholder="职务工资"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="工资类型:" prop="SalaryType">
                                        <el-select v-model="SalaryBanksForm.SalaryType"
                                                   placeholder="工资类型"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="日保健费:" prop="CareDaySalary">
                                        <el-select v-model="SalaryBanksForm.CareDaySalary"
                                                   placeholder="日保健费"
                                                   style="width: 100%">
                                            <el-option label="0" value="0"></el-option>
                                            <el-option label="5" value="5"></el-option>
                                            <el-option label="10" value="10"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="日工资单价:" prop="DaySalary">
                                        <el-input type="text" v-model="SalaryBanksForm.DaySalary">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="特岗补贴:" prop="SpecialSalary">
                                        <el-input type="text"
                                                  v-model="SalaryBanksForm.SpecialSalary">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="伙食单价:" prop="EateDaySalary">
                                        <el-input type="text" v-model="SakaryForm.EateDaySalary">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="驻外补贴:" prop="OutsideSalary">
                                        <el-input type="text"
                                                  v-model="SalaryBanksForm.OutsideSalary">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="车辆补贴:" prop="BusMoney">
                                        <el-input type="text" v-model="SalaryBanksForm.BusMoney">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="值班日保健费:" prop="OndutyCareSalary">
                                        <el-input type="text"
                                                  v-model="SalaryBanksForm.OndutyCareSalary">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="爱心基金:" prop="LoveMoney">
                                        <el-input type="text" v-model="SalaryBanksForm.LoveMoney">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="工人系数:" prop="WorkerMod">
                                        <el-input type="text" v-model="SalaryBanksForm.WorkerMod">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="租房补贴:" prop="HouseSalary">
                                        <el-input type="text" v-model="SalaryBanksForm.HouseSalary">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="绩效工资:" prop="PerfSalary">
                                        <el-input type="text" v-model="SalaryBanksForm.PerfSalary">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="备注:" prop="Remark">
                                        <el-input type="textarea" v-model="SalaryBanksForm.Remark">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-dialog>
                </el-tab-pane>
                <el-tab-pane label="转正信息" name="转正信息">
                    <el-table :data="Positive" highlight-current-row>
                        <el-table-column prop="UserInfoUserName" label="姓名">
                        </el-table-column>
                        <el-table-column prop="Factory" label="公司名称"> </el-table-column>
                        <el-table-column prop="Dpt" label="部门名称"> </el-table-column>
                        <el-table-column prop="Post" label="岗位名称"> </el-table-column>
                        <el-table-column label="转正日期"
                                         prop="StartDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="薪资类型"
                                         prop="DicSalaryTypeType"></el-table-column>
                        <el-table-column label="职务待遇"
                                         prop="DicPositionLeaveName"></el-table-column>
                        <el-table-column label="调动时间"
                                         prop="TransferDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column fixed="right" label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="PositiveDetail(scope.row)"
                                           type="text"
                                           size="small">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-dialog title="转正详情"
                               :visible.sync="permissionShowVisible"
                               v-model="permissionShowVisible"
                               :close-on-click-modal="false">
                        <el-form label-width="120px"
                                 ref="PermissionForm"
                                 :model="PermissionForm">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="岗位:" prop="Post">
                                        <el-input type="text"
                                                  :disabled="true"
                                                  v-model="PermissionForm.Post">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="伙食日单价:" prop="EateSalary">
                                        <el-select v-model="PermissionForm.EateSalary"
                                                   placeholder="伙食日单价"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="12">
                                    <el-form-item prop="StartDate" label="转正日期:">
                                        <el-date-picker type="date"
                                                        v-model="PermissionForm.StartDate"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="12">
                                    <el-form-item label="转正工资执行时间:" prop="SalaryDate">
                                        <el-date-picker type="month"
                                                        v-model="PermissionForm.SalaryDate"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="日保健费:">
                                        <el-select v-model="PermissionForm.CareDaySalary"
                                                   placeholder="日保健费"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="12">
                                    <el-form-item label="驻外补贴:">
                                        <el-select v-model="PermissionForm.OutsideSalary"
                                                   placeholder="驻外补贴"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="特岗补贴:">
                                        <el-input v-model="PermissionForm.SpecialSalary"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="车辆补贴:">
                                        <el-input v-model="PermissionForm.BusSalary"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="岗位工资:">
                                        <el-input v-model="PermissionForm.WorkSalary"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="日工资:">
                                        <el-input v-model="PermissionForm.DaySalary"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="爱心基金:">
                                        <el-input v-model="PermissionForm.FundsDeduct"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="银行:">
                                        <el-input v-model="PermissionForm.BankCardName"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="开户行:">
                                        <el-input v-model="PermissionForm.BankAddress"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="银行卡号:">
                                        <el-input v-model="PermissionForm.BankCardNo"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="薪资类型:" prop="DicSalaryTypeType">
                                        <el-select v-model="PermissionForm.DicSalaryTypeType"
                                                   @change="SalaryTypeModel($event)"
                                                   placeholder="薪资类型"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="12">
                                    <el-form-item label="职位待遇:" prop="DicPositionLeaveName">
                                        <el-select v-model="PermissionForm.DicPositionLeaveName"
                                                   @change="PositionModel($event)"
                                                   placeholder="职位待遇"
                                                   style="width: 100%">
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <upload-files :files="PermissionForm.FileArry"
                                          :key="key"
                                          action="/cyl/ftp/ftp/upload"
                                          :limit="20"
                                          :IsDisabled="false"
                                          :IsDel="false"></upload-files>
                        </el-form>
                    </el-dialog>
                </el-tab-pane>
                <el-tab-pane label="职称信息" name="职称信息">
                    <el-table :data="Professions" highlight-current-row>
                        <el-table-column prop="UserName" label="姓名"> </el-table-column>
                        <el-table-column label="公司" prop="Factory"></el-table-column>
                        <el-table-column label="部门" prop="Dpt"></el-table-column>
                        <el-table-column label="岗位" prop="Post"></el-table-column>
                        <el-table-column label="职称类型" prop="Type"></el-table-column>
                        <el-table-column label="评聘级别" prop="Rank"></el-table-column>
                        <el-table-column label="评聘档级" prop="Level"></el-table-column>
                        <el-table-column label="职称工资" prop="Salary"></el-table-column>
                        <el-table-column label="评聘日期"
                                         prop="JudgedDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="发放日期"
                                         prop="ExecuteDate"
                                         :formatter="formatStartTime"></el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="首席工程师" name="首席工程师">
                    <el-table :data="ProfessionTop" highlight-current-row>
                        <el-table-column prop="UserName" label="姓名"> </el-table-column>
                        <el-table-column label="公司" prop="Factory"></el-table-column>
                        <el-table-column label="部门" prop="Dpt"></el-table-column>
                        <el-table-column label="岗位" prop="Post"></el-table-column>
                        <el-table-column label="首席工程师工资"
                                         prop="Salary"></el-table-column>
                        <el-table-column label="评聘日期"
                                         prop="JudgedDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="发放日期"
                                         prop="ExecuteDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="方向" prop="Profession"></el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="员工附件" name="员工附件">
                    <el-form ref="SalaryBanksForm"
                             class="demo-form-inline"
                             label-width="120px">
                        <el-row>
                            <upload-files :files="UploadForm"
                                          :key="key"
                                          action="/cyl/ftp/ftp/upload"
                                          :IsDisabled="false"
                                          :IsDel="false"
                                          :limit="20"></upload-files>
                        </el-row>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="奖惩信息" name="奖惩信息">
                    <el-table :data="Rewards" highlight-current-row>
                        <el-table-column prop="UserName" label="姓名"> </el-table-column>

                        <el-table-column label="部门" prop="Dpt"></el-table-column>
                        <el-table-column label="岗位" prop="Post"></el-table-column>
                        <el-table-column label="发布部门"
                                         prop="IssuedDepartment"></el-table-column>
                        <el-table-column label="执行日期"
                                         prop="ExecuteDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="执行方式"
                                         prop="ExecuteType"></el-table-column>
                        <el-table-column label="类型" prop="Type"></el-table-column>
                        <el-table-column label="金额" prop="Salary"></el-table-column>
                        <el-table-column label="具体描述"
                                         show-overflow-tooltip
                                         prop="Explain"></el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="专利信息" name="专利信息">
                    <el-table :data="PatentList" @row-dblclick="ondblclick" highlight-current-row>
                        <el-table-column label="序号" prop="RowIndex"></el-table-column>
                        <el-table-column label="专利类型" prop="Type"></el-table-column>
                        <el-table-column label="专利名称" prop="PatentName"> </el-table-column>
                        <el-table-column label="专利号" prop="PatentNumber"></el-table-column>
                        <el-table-column label="申请人" prop="Applicant"></el-table-column>
                        <el-table-column label="专利法律状态" prop="State"></el-table-column>
                        <el-table-column label="授权公众号" prop="AnnouncementNumber"></el-table-column>
                        <el-table-column label="证书编号" prop="CertificateNumber"></el-table-column>
                        <el-table-column label="申请日期" prop="ApplicationDate"></el-table-column>
                        <el-table-column label="授权日期" prop="AuthorizationDate"></el-table-column>
                        <el-table-column label="有效日期" prop="EffectiveDate"></el-table-column>
                        <el-table-column label="获取方式" prop="AcquireWay"></el-table-column>
                        <el-table-column label="申请年度" prop="ApplicationYear"></el-table-column>
                        <el-table-column label="授权年度" prop="AnnouncementYear"></el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="论文信息" name="论文信息">
                    <el-table :data="ThesisList" @row-dblclick="ondblclick" highlight-current-row>
                        <el-table-column label="序号" prop="RowIndex"></el-table-column>
                        <el-table-column label="论文名称" prop="ThesisName"></el-table-column>
                        <el-table-column label="期刊名称" prop="JournalName"> </el-table-column>
                        <el-table-column label="期刊级别" prop="JournalRank"></el-table-column>
                        <el-table-column label="发表年度" prop="PublishYear"></el-table-column>
                        <el-table-column label="作者单位" prop="AuthorUnit"></el-table-column>
                        <el-table-column label="备注" prop="Remake"></el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="培训信息" name="培训信息">
                    <el-table :data="TrainList" highlight-current-row>
                        <el-table-column prop="Apply_QRCode" label="培训编号"> </el-table-column>
                        <e
                        <el-table-column prop="ProjectType" label="项目类型"> </el-table-column>
                        <el-table-column prop="Apply_ProjectName" label="培训名称"> </el-table-column>
                        <el-table-column prop="TrainWay" label="培训方式"> </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>
        <patentModal ref="patentModal"></patentModal>
        <thesisModal ref="thesisModal"></thesisModal>-->
    </div> 
    
</template>

<script>
import util from "../../util/date";
import UploadFiles from "../components/UploadFiles";
import {
  GetCurrentUserInfo,
  GetAtPresentBaseInfo,
  ContractGetInfoList,
  CertificateGetInfoList,
  TransferGetInfoList,
  HonorGetInfoList,
  GetHonorDetail,
  DimissionGetInfoList,
  PositiveGetPositiveList,
  getGetPositiveDetail,
  SalaryBanksGetInfoList,
  ProfessionGetInfoList,
  ProfessionTopGetInfoList,
  GetAtPresentSalaryBanksInfo,
  UserInfoGetFileArrayList,
  UserUpdateUserImage,
        UserInfoGetRewardsList,
        getResearchResultInfoList,
        getPatentInfoList,
        getThesisInfoList,
        GetTrainSingInList
    } from "../api/hr";

    import patentModal from "./componentModals/patentModal"    // 专利
    import thesisModal from "./componentModals/thesisModal"    // 论文

export default {
        components: { UploadFiles, patentModal, thesisModal },
  data() {
    return {
        activeName: "基本信息",
        tabType: "基本信息", //当前所在的标签页
      userForm: {
        UserName: null,
        Dpt: null,
        Hiredate: null,
        Post: null,
        MobilePhone: null,
        ImageURL: null,
      },
      dataForm: {
        ApplyDate: null,
        UserName: null,
      },
      Contract: null,
      Certificate: null,
      SakaryForm: {
        UserName: null,
      },
      SakaryVisible: false,
      key: 0,
      Transfer: null,
      Honor: null,
      TransferForm: {
        UserName: null,
      },
      TransferFormVisible: false,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      HonorForm: {
        UserName: null,
      },
      HonorVisible: false,
      Dimission: null,
      Positive: null,
      PermissionForm: {
        Post: null,
      },
      permissionShowVisible: false,
      SalaryBanks: null,
      SalaryBanksForm: {
        UserName: null,
      },
      SalaryBanksVisible: false,
      Professions: null,
      ProfessionTop: null,
      PresentSalary: {
        UserName: null,
      },
      UploadForm: null,
        Rewards: [],
        researchResult: [], // 科技成果
        PatentList: [], // 专利
        ThesisList: [], // 论文
        TrainList:[],// 培训
    };
  },
  methods: {
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleClick(tab, event) {
          if (this.tabType == tab.name)
              return;
          this.tabType = tab.name;
      switch (tab.name) {
        case "基本信息":
          this.getData();
          break;
        case "档案信息":
          //  this.getData();
          break;
        case "合同信息":
          this.getContract();
          break;
        case "证书信息":
          this.getCertificate();
          break;
        case "调动信息":
          this.getTransfer();
          break;
        case "荣誉信息":
          this.getHonor();
          break;
        case "离职记录":
          this.getDimission();
          break;
        case "转正信息":
          this.getPositive();
          break;
        case "薪资调整记录":
          this.getSalaryBanks();
          break;
        case "职称信息":
          this.getProfession();
          break;
        case "首席工程师":
          this.getProfessionTop();
          break;
        case "薪资信息":
          this.getAtPresentSalaryBanksInfo();
          break;
        case "员工附件":
              this.getFilesArray();
              break;
        case "奖惩信息":
          this.getRewards();
              break;
        case "专利信息":
              this.getPatent();
              break;
          case "论文信息":
              this.getThesis();
              break;
          case "培训信息":
              this.getTrain();
              break;
          case "科技成果":
              this.getResearchResult();
              break;
      }
    },
    ondblclick(row, column, cell, event) {
        //let row = this.currentRow;
        switch (this.tabType) {
            case '专利信息':
                {
                    this.$refs.patentModal.onShowDlg(row.Id);
                } break;
            case '论文信息':
                {
                    this.$refs.thesisModal.onShowDlg(row.Id);
                } break;
            case '科技成果':
                {
                    if (row.Classify === "论文")
                        this.$refs.thesisModal.onShowDlg(row.Id);
                    else
                        this.$refs.patentModal.onShowDlg(row.Id);
                } break;
        }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getHonor();
    },
    CertificateDetail(row) {
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.SakaryForm = Object.assign({}, row);
      this.key += 1;
      this.SakaryVisible = true;
    },
    getData() {
      GetAtPresentBaseInfo({ userId: this.userForm.UserId }).then((res) => {
        if (res.data.response != null) {
          this.dataForm = res.data.response;
          this.dataForm.ApplyDate = util.formatDate.format(
            new Date(this.dataForm.ApplyDate),
            "yyyy-MM-dd"
          );
          this.dataForm.Birthday = util.formatDate.format(
            new Date(this.dataForm.Birthday),
            "yyyy-MM-dd"
          );
        }
      });
    },
    getContract() {
      ContractGetInfoList({ userId: this.userForm.UserId }).then((res) => {
        this.Contract = res.data.response;
      });
    },
    getCertificate() {
      CertificateGetInfoList({ userId: this.userForm.UserId }).then((res) => {
        this.Certificate = res.data.response;
      });
    },
    getTransfer() {
      TransferGetInfoList({ userId: this.userForm.UserId }).then((res) => {
        this.Transfer = res.data.response;
      });
    },
    getHonor() {
      HonorGetInfoList({
        userId: this.userForm.UserId,
        page: this.page,
        size: this.pageSize,
      }).then((res) => {
        this.Honor = res.data.response.data;
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
      });
    },
    TransferDetail(row) {
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.TransferForm = Object.assign({}, row);
      this.key += 1;
      this.TransferFormVisible = true;
    },
    getFilesArray() {
      UserInfoGetFileArrayList({ PId: this.userForm.UserId }).then((res) => {
        this.key += 1;
        this.UploadForm = res.data.response;
      });
    },
    HonorDetail(row) {
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      GetHonorDetail({ id: row.Id }).then((res) => {
        this.HonorForm = res.data.response;
        this.key += 1;
        this.HonorVisible = true;
      });
    },

    getDimission() {
      DimissionGetInfoList({ userId: this.userForm.UserId }).then((res) => {
        this.Dimission = res.data.response;
      });
    },
    getPositive() {
      PositiveGetPositiveList({ userId: this.userForm.UserId }).then((res) => {
        this.Positive = res.data.response;
      });
    },
    PositiveDetail(row) {
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      getGetPositiveDetail({ id: row.Id }).then((res) => {
        this.PermissionForm = res.data.response;
        this.key += 1;
        this.permissionShowVisible = true;
      });
    },
    getSalaryBanks() {
      SalaryBanksGetInfoList({ userId: this.userForm.UserId }).then((res) => {
        this.SalaryBanks = res.data.response;
      });
    },
    getRewards() {
      UserInfoGetRewardsList({ userId: this.userForm.UserId }).then((res) => {
        this.Rewards = res.data.response;
      });
    },
    getPatent() {
        getPatentInfoList({ userId: this.userForm["UserId"] }).then(
            (res) => {
                this.PatentList = res.data.response.data;
            }
        );
    },
    getThesis() {
        getThesisInfoList({ userId: this.userForm["UserId"] }).then(
            (res) => {
                this.ThesisList = res.data.response.data;
            }
        );
      },
      getTrain() {
          GetTrainSingInList({ userId: this.userForm["UserId"] }).then(
              (res) => {
                  this.TrainList = res.data.response;
              }
          );
      },
    getResearchResult() {
        getResearchResultInfoList({ userId: this.userForm["UserId"] }).then(
            (res) => {
                console.log(res)
                this.researchResult = res.data.response.data;
            }
        );
    },
    SalaryBanksDetail(row) {
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.SalaryBanksForm = Object.assign({}, row);
      this.key += 1;
      this.SalaryBanksVisible = true;
    },
    getProfession() {
      console.log(1111);
      ProfessionGetInfoList({ userId: this.userForm.UserId }).then((res) => {
        this.Professions = res.data.response;
      });
    },
    getProfessionTop() {
      ProfessionTopGetInfoList({ userId: this.userForm.UserId }).then((res) => {
        console.log(res);
        this.ProfessionTop = res.data.response;
      });
    },
    getAtPresentSalaryBanksInfo() {
      GetAtPresentSalaryBanksInfo({ userId: this.userForm.UserId }).then(
        (res) => {
          console.log(res);
          this.PresentSalary = res.data.response;
        }
      );
    },
    // OSOnSuccess(response, file, fileList) {
    // 	this.userForm.ImageURL =
    //     response.response.HttpHost + response.response.RemotePath
    //     this.UserUpdateUserImage()
    // },

    UserUpdateUserImage() {
      UserUpdateUserImage(this.userForm).then((res) => {
        this.Professions = res.data.response;
      });
    },
  },
  async mounted() {
    //  this.$router.go(0)
    await GetCurrentUserInfo().then((res) => {
      if (res.data.response != null) {
        this.userForm = res.data.response;
        this.userForm.Hiredate = util.formatDate.format(
          new Date(this.userForm.Hiredate),
          "yyyy-MM-dd"
        );
      }
    });
    this.getData();
  },
};
</script>
<style  scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
.name-role {
  font-size: 16px;
  padding: 5px;
  text-align: center;
}
.sender {
  text-align: center;
}
.el-row {
  margin-bottom: 10px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.registe-info {
  text-align: center;
  padding-top: 10px;
}
.personal-relation {
  font-size: 13px;
  /* padding: 0px 5px 15px; */
  margin-right: 1px;
  width: 100%;
}

.relation-item {
  padding: 12px;
}
.dialog-footer {
  padding-top: 10px;
  padding-left: 10%;
}

.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 0;
}
.el-card__header {
  background-color: black;
  padding: 9px 20px;
  border-bottom: 4px solid #ebecf5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
<style>
.new_content .el-tabs__item.is-active {
  color: #eb1964;
}
.new_content .grid-content .el-card__header {
  background-color: #dce4f1;
  padding: 9px 20px;
  border-bottom: 1px solid #ebecf5;
  border-left: 3px solid #2b67d5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.new_content .el-tabs__active-bar {
  background-color: #eb1964;
}
.new_content .el-tabs__item:hover {
  color: #eb1964;
  /*color: #FFFFFF;*/
}
.new_content .el-table th > .cell {
  color: #eb1964;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}
.new_content .el-table__empty-text {
  display: none;
  line-height: 60px;
  width: 50%;
  color: #909399;
}
</style>
